import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/Login.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/request-password',
    name: 'RequestPassword',
    component: () => import('@/views/auth/RequestPassword.vue'),
  },
  {
    path: '/reset-password/:hash/:userid',
    name: 'RequestPassword',
    component: () => import('@/views/auth/ResetPassword.vue'),
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('@/views/Users/Users.vue'),
  },
  {
    path: '/links',
    name: 'Links',
    component: () => import('@/views/Links/Links.vue'),
  },
  {
    path: '/add-timed-link',
    name: 'AddTimedLink',
    component: () => import('@/views/Links/AddTimedLink.vue'),
  },
  {
    path: '/add-live-link',
    name: 'Links',
    component: () => import('@/views/Links/AddLiveLink.vue'),
  },
  {
    path: '/link-list',
    name: 'LinkList',
    component: () => import('@/views/Links/LinkList.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
